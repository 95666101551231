import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Form, Input, Card, Icon, Button,
} from 'antd';
import { login } from './ducks';
import { error } from './selector';
import { generateOtpEmail } from '../waybill/newDucks';

export class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      values: {},
      isLoadingLogin: false,
      errorLogin: null,
      otpCountDown: 0
    };
 
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    setInterval((e) => {
      if(this.state.otpCountDown > 0){
        this.setState(state => ({otpCountDown : state.otpCountDown - 1}));
      }
    },1000);
  }

  handleOnChange(e) {
    e.persist();
    this.setState(prevState => ({
      values: {
        ...prevState.values,
        [e.target.name]: e.target.value,
      },
    }));
  }
    
  handleOnSubmit(e) {
    const { values } = this.state;
    const { username, password,otpcode } = values;
    const isPortal = true;
    if(this.state.otpCountDown > 0){
      e.preventDefault();
      this.setState({ isLoadingLogin: true });
      const { doLogin } = this.props;

      doLogin(username, password, otpcode,isPortal).then((res) => {
        this.setState({ isLoadingLogin: false, errorLogin: res.error.response.data.message });
      }).catch(() => {
        this.setState({ isLoadingLogin: false, errorLogin: 'Network error, Try again later.' });
      });
      
    }else{
        const { doGenerateOtp } = this.props;
        e.preventDefault();
        this.setState({ isLoadingLogin: true });
      doGenerateOtp(values).then((res) => {
        this.setState({ isLoadingLogin: false });
        this.setState(state => ({otpCountDown : 300}));

      }).catch((res) => {
        let payload = res.error.response.data;
        if(payload.code === 400){
          this.setState({ otpCountDown: 0 });
        }
        this.setState({ isLoadingLogin: false, errorLogin: res.error.response.data.message });
      });
    }
    
  }

  render() {
    const { hasError } = this.props;
    const { values, isLoadingLogin, errorLogin } = this.state;
    let errorMessage = false;

    if (errorLogin) {
      errorMessage = <span style={{ color: 'red', textAlign: 'center' }}>{errorLogin}</span>;
    }

    return (
      <div className="Login">
        <div style={{ textAlign: 'center' }}>
          <Card style={{ marginTop: '15vh', display: 'inline-block', width: 300 }}>
            <Form onSubmit={this.handleOnSubmit}>
              <div style={{ textAlign: 'center', marginBottom: '25px' }}>
                <img src="/img/logo-colored.png" alt="Logo" width="120" />
              </div>
              {errorMessage || null}
              {/* {errorLogin} */}
              <Form.Item style={{ marginBottom: '15px' }}>
                <Input
                  name="username"
                  onChange={this.handleOnChange}
                  value={values.username}
                  placeholder="Username"
                  prefix={<Icon type="user" />}
                />
              </Form.Item>
              <Form.Item style={{ marginBottom: '15px' }}>
                <Input.Password
                  name="password"
                  onChange={this.handleOnChange}
                  value={values.password}
                  placeholder="Password"
                  prefix={<Icon type="lock" />}
                />
              </Form.Item>
              <Form.Item style={{ marginBottom: '15px' }}>
                {this.state.otpCountDown > 0 ? (<Input
                  name="otpcode"
                  onChange={this.handleOnChange}
                  value={values.otpcode}
                  placeholder="OTP CODE"
                 
                />) : ''}
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  shape="round"
                  block
                  disabled={isLoadingLogin}
                >
                  Login
                </Button>
                <p>{this.state.otpCountDown > 0 ? this.state.otpCountDown + ' seconds left before the OTP expires, please check your registered email.' : ''}</p>
              </Form.Item>
            </Form>
          </Card>
        </div>
        <div style={{ textAlign: 'center', marginTop: '4px' }}>
          <span style={{ color: 'rgba(0,0,0,0.25)', fontSize: '12px' }}>
            XDE Logistics
            { ' - ' }
            { process.env.REACT_APP_GIT_SHA }
          </span>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  hasError: error(state),
});

const mapDispatchToProps = {
  doLogin: login,
  doGenerateOtp: generateOtpEmail
};

Login.propTypes = {
  doLogin: PropTypes.func,
  doGenerateOtp: PropTypes.func,
  hasError: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

Login.defaultProps = {
  doLogin: null,
  doGenerateOtp: null,
  hasError: false,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Login),
);
