import {
  apiFetchList,
  apiCreateShipperDriver,
  apiUpdateClient,
  apiCreateClientStatusMap,
  apiFetchClientStatusMap,
  apiUpdateClientStatusMap,
  apiDeleteShipperDriver,
  apiFetchStatusMapList,
} from '../../services/apiEndpoints';

const FETCH_SHIPPER_DRIVER_LIST = 'shipper-driver/SHIPPER_DRIVER_LIST';
export const fetchShipperDriverList = data => ({
  type: FETCH_SHIPPER_DRIVER_LIST,
  payload: {
    client: 'secure',
    request: {
      ...apiFetchList('shipper-driver'),
      data,
    },
  },
});
const DELETE_SHIPPER_DRIVER = 'shipper-driver/DELETE_SHIPPER_DRIVER';
export const deleteShipperDriver = id => ({
  type: DELETE_SHIPPER_DRIVER,
  payload: {
    client: 'secure',
    request: {
      ...apiDeleteShipperDriver(id),
    },
  },
});
const CREATE_SHIPPER_DRIVER = 'client/CREATE_SHIPPER_DRIVER';
export const createShipperDriver = data => ({
  type: CREATE_SHIPPER_DRIVER,
  payload: {
    client: 'secure',
    request: {
      ...apiCreateShipperDriver,
      data,
    },
  },
});

const UPDATE_CLIENT = 'vehicle/UPDATE_CLIENT';
export const updateClient = (clientId, data) => ({
  type: UPDATE_CLIENT,
  payload: {
    client: 'secure',
    request: {
      ...apiUpdateClient(clientId),
      data,
    },
  },
});

const CREATE_STATUS_MAP = 'client/CREATE_STATUS_MAP';
export const createClientStatusMap = data => ({
  type: CREATE_STATUS_MAP,
  payload: {
    client: 'secure',
    request: {
      ...apiCreateClientStatusMap,
      data,
    },
  },
});

const FETCH_STATUS_MAP_LIST = 'client/FETCH_STATUS_MAP_LIST';
export const fetchStatusMapList = () => ({
  type: FETCH_STATUS_MAP_LIST,
  payload: {
    client: 'secure',
    request: {
      ...apiFetchStatusMapList,
    },
  },
});

const FETCH_CLIENT_STATUS_MAP = 'client/FETCH_CLIENT_STATUS_MAP';
export const fetchClientStatusMap = (id) => ({
  type: FETCH_CLIENT_STATUS_MAP,
  payload: {
    client: 'secure',
    request: {
      ...apiFetchClientStatusMap(id),
    },
  },
});

const UPDATE_STATUS_MAP = 'client/UPDATE_STATUS_MAP';
export const updateClientStatusMap = (data, id) => ({
  type: UPDATE_STATUS_MAP,
  payload: {
    client: 'secure',
    request: {
      ...apiUpdateClientStatusMap(id),
      data,
    },
  },
});

const DELETE_STATUS_MAP = 'client/DELETE_STATUS_MAP';



const initialState = {};

function reducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}

export default reducer;
