import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faRedo } from '@fortawesome/free-solid-svg-icons';
import {
  Form, Col, Button, Icon, Input, Alert, message, Spin, Modal, Result,
} from 'antd';
import Row from '../../components/Row';
import Select from '../../components/Select';
import PageHeader from '../../components/PageHeader';
import Container from '../../components/Container';
import Card from '../../components/Card';
import {
  fetchOptions, fetchUserDetails,
  fetchOptionsLike, updateUser, resetPassword,
} from './ducks';

export class Edit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDetails: {},
      options: {
        role: [],
        mobile_role: [],
        destination: [],
        client: [],
        outsource: [],
      },
      isFetching: false,
      roleValidate: false,
      isMountingModule: true,
      isVisible: false,
      newPassword: null,
      isResetting: false,
      loadingMessage: 'Fetching user details . . .',
    };

    this.formSubmit = this.formSubmit.bind(this);
    this.searchOption = this.searchOption.bind(this);
    this.showResetModal = this.showResetModal.bind(this);
    this.resetPasswordHandler = this.resetPasswordHandler.bind(this);
    this.cancelResetPasswordHandler = this.cancelResetPasswordHandler.bind(this);
    this.clientSelectHandler = this.clientSelectHandler.bind(this);
    this.roleValidator = this.roleValidator.bind(this);
  }

  componentDidMount() {
    this.optionsHandler();
    this.userDetailsHandler();
  }

  userDetailsHandler() {
    const { doFetchUserDetails, match } = this.props;
    doFetchUserDetails(match.params.id).then((action) => {
      this.setState({
        userDetails: {
          ...action.payload.data,
          user_clients: action.payload.data.user_clients.map(client => client.client_id),
        },
        isMountingModule: false,
      });
    });
  }

  roleValidator(e) {
    if (e === 'courier') this.setState({ roleValidate: true, });
    else this.setState({ roleValidate: false, });
  }

  optionsHandler() {
    const { doFetchOptions } = this.props;

    const selectType = [
      'mobile_role',
      'role',
      'destination',
      'client',
      'outsource',
    ];

    Object.keys(selectType).forEach((key) => {
      doFetchOptions(selectType[key]).then((option) => {
        this.setState(prevState => ({
          options: {
            ...prevState.options,
            [selectType[key]]: option.payload.data,
          },
        }));
      });
    });
  }

  searchOption(type, e) {
    const { doFetchOptionsLike } = this.props;

    this.setState({
      isFetching: true,
    });

    doFetchOptionsLike(type, e).then((action) => {
      this.setState(prevState => ({
        options: {
          ...prevState.options,
          [type]: action.payload.data.slice(0, 50),
        },
        isFetching: false,
      }));
    });
  }

  showResetModal() {
    this.setState({
      isVisible: true,
    });
  }

  resetPasswordHandler() {
    const { doResetPassword, match } = this.props;

    this.setState({
      isResetting: true,
    });

    doResetPassword(match.params.id).then((action) => {
      this.setState({
        newPassword: action.payload.data.password,
        isResetting: false,
      });
    }).catch(() => {
      message.error('Something went wrong, please try again later.');
      this.setState({
        isResetting: false,
      });
    });
  }

  cancelResetPasswordHandler() {
    this.setState({
      isVisible: false,
      newPassword: null,
    });
  }

  formSubmit(e) {
    e.preventDefault();

    const { doUpdateUser, form, match } = this.props;
    const { validateFields } = form;

    validateFields((err, values) => {
      if (!err) {
        this.setState({
          isMountingModule: true,
          loadingMessage: 'Updating user details . . .',
        });
        doUpdateUser(match.params.id, values).then(() => {
          message.success('Successfully updated user!');
          this.setState({
            isMountingModule: false,
          });
        }).catch((action) => {
          this.setState({
            isMountingModule: false,
          });
          let errorMessage;
          if (action.error.response.status === 400) {
            errorMessage = action.error.response.data.message;
          } else {
            errorMessage = 'Something went wrong, please try again later.';
          }
          message.error(errorMessage, 3);
        });
      }
    });
  }

  clientSelectHandler() {
    const { form } = this.props;
    const { getFieldValue, setFields } = form;

    if (getFieldValue('clients').length > 5) {
      setFields({
        clients: {
          value: getFieldValue('clients'),
          errors: [new Error('Cannot select more than 5 clients.')],
        },
      });
    }
  }

  render() {
    const {
      userDetails, options, isFetching, isMountingModule, isVisible, newPassword,
      isResetting, loadingMessage, roleValidate,
    } = this.state;
    const { form, match } = this.props;
    const { getFieldDecorator } = form;
    const breadCrumbs = [
      {
        breadcrumbName: 'Admin',
      },
      {
        breadcrumbName: 'User',
      },
      {
        breadcrumbName: 'List',
      },
      {
        breadcrumbName: `Edit #${match.params.id}`,
      },
    ];

    return (
      <div className="UserEdit">
        <Spin spinning={isMountingModule} size="small" tip={loadingMessage}>
          <PageHeader title={`Users #${match.params.id}`} routes={breadCrumbs} />
          <Container>

            <Modal
              title="Reset Password"
              closable={false}
              visible={isVisible}
              afterClose={this.clearResetState}
              footer={[
                <Button key="cancel" type="default" onClick={this.cancelResetPasswordHandler} disabled={isResetting}>
                Cancel
                </Button>,
                <Button key="reset" type="primary" onClick={this.resetPasswordHandler} loading={isResetting}>
                Reset
                </Button>,

              ]}
            >
              <Result
                status={newPassword === null ? 'info' : 'success'}
                title={newPassword === null
                  ? 'Are you sure you want to reset this user\'s password ?'
                  : 'Success password reset!'}
                subTitle={newPassword !== null ? `Password is: ${newPassword}` : null}
              />
            </Modal>
            <Card
              title="User Information"
              extra={
              userDetails.is_active === 0 ? (
                <React.Fragment>
                  <Alert
                    message="This user is currently inactive, please contact the system administrator for further assistance."
                    type="warning"
                    showIcon
                  />
                </React.Fragment>
              ) : null}
            >
              <Form onSubmit={this.formSubmit}>
                <Row>
                  <Col xs={24} sm={12} lg={6}>
                    <Form.Item label="Username">
                      {getFieldDecorator('username', {
                        initialValue: userDetails ? userDetails.username : '',
                      })(<Input placeholder="Enter username" autoComplete="off" disabled />)}
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} lg={6}>
                    <Form.Item label="First Name">
                      {getFieldDecorator('firstname', {
                        rules: [{ required: true, message: 'First Name cannot be blank.' }],
                        initialValue: userDetails ? userDetails.firstname : '',
                      })(<Input placeholder="Enter first name" autoComplete="off" />)}
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} lg={6}>
                    <Form.Item label="Last Name">
                      {getFieldDecorator('lastname', {
                        rules: [{ required: true, message: 'Last Name cannot be blank.' }],
                        initialValue: userDetails ? userDetails.lastname : '',
                      })(<Input placeholder="Enter last name" autoComplete="off" />)}
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} lg={6}>
                    <Form.Item label="Employee Number">
                      {getFieldDecorator('employee_no', {
                        rules: [{ required: true, message: 'Employee Number cannot be blank.' }],
                        initialValue: userDetails ? userDetails.employee_no : '',
                      })(<Input placeholder="Enter employee number" autoComplete="off" />)}
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} sm={12} lg={6}>
                    <Form.Item label="Mobile Number.">
                      {getFieldDecorator('mobile_no', {
                        rules: [{ required: true, message: 'Mobile Number. cannot be blank.' }],
                        initialValue: userDetails ? userDetails.mobile_no : '',
                      })(<Input placeholder="Enter mobile number" autoComplete="off" />)}
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} lg={6}>
                    <Form.Item label="Port Code">
                      {getFieldDecorator('port_code', {
                        rules: [{ required: true, message: 'Port Code cannot be blank.' }],
                        initialValue: userDetails ? userDetails.port_code : '',
                      })(<Select
                        options={options.destination}
                        placeholder="Select port code"
                        allowClear
                        loading={isFetching}
                        onSearch={e => this.searchOption('destination', e)}
                        filterOption={false}
                        showSearch
                      />)}
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} lg={6}>
                    <Form.Item label="Portal Role">
                      {getFieldDecorator('role_id', {
                        rules: [{ required: true, message: 'Portal Role cannot be blank.' }],
                        initialValue: userDetails ? userDetails.role_id : '',
                      })(<Select
                        options={options.role}
                        placeholder="Enter portal role"
                        allowClear
                        loading={isFetching}
                        onSearch={e => this.searchOption('role', e)}
                        filterOption={false}
                        showSearch
                      />)}
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} lg={6}>
                    <Form.Item label="Mobile Role">
                      {getFieldDecorator('mobile_role', {
                        rules: [{ required: true, message: 'Mobile Role cannot be blank.' }],
                        initialValue: userDetails ? userDetails.mobile_role : '',
                      })(<Select
                        options={options.mobile_role}
                        placeholder="Select mobile role"
                        allowClear
                        loading={isFetching}
                        onSearch={e => this.searchOption('mobile_role', e)}
                        onSelect={e => this.roleValidator(e)}
                        filterOption={false}
                        showSearch
                      />)}
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} sm={24} lg={roleValidate ? 12 : 18}>
                    <Form.Item label="Clients">
                      {getFieldDecorator('clients', {
                        rules: [
                          {
                            required: false,
                            message: 'Clients cannot be blank.',
                          },
                        ],
                        initialValue: userDetails ? userDetails.user_clients : [],
                      })(<Select
                        allowClear
                        mode="multiple"
                        loading={isFetching}
                        options={options.client}
                        onSearch={e => this.searchOption('client', e)}
                        filterOption={false}
                        placeholder="Type to search for desired clients"
                        showSearch
                        onSelect={this.clientSelectHandler}
                      />)}
                    </Form.Item>
                  </Col>
                  
                  {
                    roleValidate ? (
                      <Col xs={24} sm={24} lg={6}>
                        <Form.Item label="Accumatica ID">
                          {getFieldDecorator('accumatica_id', {
                            rules: [
                              {
                                required: roleValidate,
                                message: 'Accumatica ID cannot be blank.',
                              },
                            ],
                          })(
                            <Input
                              placeholder="Enter accumatica id"
                              autoComplete="off"
                            />,
                          )}
                        </Form.Item>
                      </Col>
                    ) : null
                  }
                  <Col xs={24} sm={24} lg={6}>
                    <Form.Item label="Outsource">
                      {getFieldDecorator('outsource_group', {
                        rules: [
                          {
                            required: true,
                            message: 'Outsource cannot be blank.',
                          },
                        ],
                        initialValue: userDetails ? userDetails.outsource_group : [],
                      })(
                        <Select
                          allowClear
                          loading={isFetching}
                          options={options.outsource}
                          onSearch={e => this.searchOption('outsource', e)}
                          filterOption={false}
                          placeholder="Type to search for desired outsource"
                          showSearch
                        />,
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                <Col xs={24} sm={24} lg={roleValidate ? 12 : 18}>
                    <Form.Item label="Email">
                      {getFieldDecorator('email', {
                        rules: [
                          {
                            required: false,
                            message: 'Email cannot be blank.',
                          },
                        ],
                        initialValue: userDetails ? userDetails.email : [],
                      })(<Input
                        type='email'
                        allowClear
                        placeholder="Type the email here"
                        showSearch
                      />)}
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} sm={24} lg={12}>
                    <Button type="default" onClick={this.showResetModal} block>
                      <Icon viewBox="0 0 1024 1024">
                        <FontAwesomeIcon icon={faRedo} fixedWidth />
                      </Icon>
                      Reset Password
                    </Button>
                  </Col>
                  <Col xs={24} sm={24} lg={12}>
                    <Button type="primary" htmlType="submit" block>
                      <Icon viewBox="0 0 1024 1024">
                        <FontAwesomeIcon icon={faEdit} fixedWidth />
                      </Icon>
                      Update
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Container>
        </Spin>

      </div>
    );
  }
}

Edit.propTypes = {
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  match: PropTypes.oneOfType([PropTypes.object]).isRequired,
  doFetchOptions: PropTypes.func.isRequired,
  doFetchUserDetails: PropTypes.func.isRequired,
  doFetchOptionsLike: PropTypes.func.isRequired,
  doUpdateUser: PropTypes.func.isRequired,
  doResetPassword: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  doFetchOptions: fetchOptions,
  doFetchUserDetails: fetchUserDetails,
  doFetchOptionsLike: fetchOptionsLike,
  doUpdateUser: updateUser,
  doResetPassword: resetPassword,
};

const WrappedEdit = Form.create({ name: 'UserEdit' })(Edit);

export default connect(null, mapDispatchToProps)(WrappedEdit);
