import roles from '../services/roles';
// Dashboard
import Dashboard from '../pages/dashboard/Dashboard';
import KpiDashboard from '../pages/dashboard/KpiDashboard';
import Backlogs from '../pages/dashboard/Backlogs';
import VolumeTrend from '../pages/dashboard/VolumeTrend';
import PickupCalendar from '../pages/dashboard/PickupCalendar';
import FleetPerformance from '../pages/dashboard/FleetPerformance';

// // Admin
import WaybillComponent from '../components/waybill/Waybill';
import CreateDispatch from '../components/dispatch/CreateDispatch';
import DispatchSearch from '../components/dispatch/DispatchSearch';

import BillingUnbilledList from '../pages/billing/UnbilledList';
import BillingUpload from '../pages/billing/Upload';
import BillingList from '../pages/billing/List';
import BillingReports from '../pages/billing/Reports';
import BillingViews from '../pages/billing/ListViews';

import MonitoringAccounts from '../pages/monitoring/Accounts';
import MonitoringFirstMile from '../pages/monitoring/LZDfirstmile';

import CashierRemittanceUnremittedList from '../pages/cashierRemittance/UnremittedList';
import CashierRemittanceCreate from '../pages/cashierRemittance/New';
import CashierRemittanceList from '../pages/cashierRemittance/List';
import CashierRemittanceReport from '../pages/cashierRemittance/RemittanceReport';

import CourierRemittanceCreate from '../pages/courierRemittance/Create';
import CourierRemittanceList from '../pages/courierRemittance/List';
import CourierRemittanceRemittedItems from '../pages/courierRemittance/RemittedItems';
import CourierRemittanceListViews from '../pages/courierRemittance/ListViews';

import ReportsCashierUnremitted from '../pages/reports/CashierUnremitted';
import ReportsDispatchedItems from '../pages/reports/DispatchedItems';
import ReportsItemsForPickup from '../pages/reports/ItemsForPickup';
import ReportsItemsLocation from '../pages/reports/ItemsLocation';
import ReportsPickedItems from '../pages/reports/PickedItems';
import ReportsUnremittedItems from '../pages/reports/UnremittedItems';

import ContentMissing from '../pages/errors/404';

// Admin
import UserList from '../pages/user/UserList';
import UserCreate from '../pages/user/UserCreate';
import UserEdit from '../pages/user/UserEdit';
import Repush from '../pages/repush/Repush';
import Vehicles from '../pages/vehicle/Vehicle';
import Clients from '../pages/client/Client';
import ShipperDriver from '../pages/shipper-driver/shipper-driver';
import ClientsStatusMap from '../pages/client/StatusMap';
import ClientStatusMapView from '../pages/client/ClientStatusMapView';
import Outsource from '../pages/outsource/Outsource';
import PortClusterForm from '../pages/portCluster/portClusterForm/Main';
import PortClusterUpload from '../pages/portCluster/portClusterUpload/Main';
import PortClusterCreate from '../pages/portCluster/PortClusterCreate';

// Packages - Customer Pickup
import CustomerPickupList from '../pages/customerPickup/CustomerPickupList';
import CustomerPickupDispatch from '../pages/customerPickup/CustomerPickupDispatch';
import CustomerPickupView from '../pages/customerPickup/CustomerPickupView';
import CustomerPickupDispatchEdit from '../pages/customerPickup/CustomerPickupDispatchEdit';
import CustomerPickupFailedPickup from '../pages/customerPickup/CustomerPickupFailedPickup';
import CustomerPickupAcceptByCourier from '../pages/customerPickup/CustomerPickupAcceptedByCourier';

// Packages - Merchant Pickup
import MerchantPickupList from '../pages/merchantPickup/MerchantPickupList';
import MerchantPickupDispatch from '../pages/merchantPickup/MerchantPickupDispatch';
import MerchantMultiPickupDispatch from '../pages/merchantPickup/MerchantMultiPickupDispatch';
import MerchantPickupDispatchEdit from '../pages/merchantPickup/MerchantPickupDispatchEdit';
import MerchantPickupFailedPickup from '../pages/merchantPickup/MerchantPickupFailedPickup';
import MerchantPickupView from '../pages/merchantPickup/MerchantPickupView';
import MerchantPickupRefusal from '../pages/merchantPickup/MerchantPickupRefusal';
import MerchantPickupReleased from '../pages/merchantPickup/MerchantPickupReleased';
import MerchantPickupRejected from '../pages/merchantPickup/MerchantPickupRejected';
import MerchantPickupDropOff from '../pages/merchantPickup/MerchantPickupDropOff';
import MerchantPickupAcceptedByCourier from '../pages/merchantPickup/MerchantPickupAcceptedByCourier';
import MerchantPickupTransmittalList from '../pages/merchantPickup/MerchantPickupTransmittalList';
import MerchantPickupTransmittalView from '../pages/merchantPickup/MerchantPickupTransmittalView';

// Packages - Delivery
import DeliveryList from '../pages/delivery/DeliveryList';
import DeliveryDispatch from '../pages/delivery/DeliveryDispatch';
import DeliveryView from '../pages/delivery/DeliveryView';
import DeliveryDispatchEdit from '../pages/delivery/DeliveryDispatchEdit';
import DeliveryDispatchPODReturned from '../pages/delivery/DeliveryDispatchPODReturned';
import DeliveryDelivered from '../pages/delivery/DeliveryDelivered';
import DeliveryFailed from '../pages/delivery/DeliveryFailed';

// Packages - Pickup
import PickupList from '../pages/pickup/PickupList';
import PickupView from '../pages/pickup/PickupView';
import PickupDispatchEdit from '../pages/pickup/PickupDispatchEdit';
import PickupDispatch from '../pages/pickup/PickupDispatch';

// Packages - Transfer
import TransferList from '../pages/transfer/TransferList';
import TransferView from '../pages/transfer/TransferView';
import TransferDispatch from '../pages/transfer/TransferDispatch';
import TransferDispatchEdit from '../pages/transfer/TransferDispatchEdit';

// Packages - RTS
import RTSList from '../pages/rts/RTSList';
import RTSDispatch from '../pages/rts/RTSDispatch';
import RTSTransferDispatch from '../pages/rts/RTSTransferDispatch';
import RTSView from '../pages/rts/RTSView';
import RTSDispatchEdit from '../pages/rts/RTSDispatchEdit';
import RTSReturned from '../pages/rts/RTSReturned';
import RTSRefused from '../pages/rts/RTSRefused';
import RTSPODReturned from '../pages/rts/RTSPODReturned';

// Package - RTM
import RTMList from '../pages/rtm/RTMList';
import RTMRefused from '../pages/rtm/RTMRefused';
import RTMReturned from '../pages/rtm/RTMReturned';
import RTMDispatch from '../pages/rtm/RTMDispatch';
import RTMDispatchEdit from '../pages/rtm/RTMDispatchEdit';
import RTMView from '../pages/rtm/RTMView';

// Packages - Accept to Warehouse
import AcceptToWarehouse from '../pages/acceptToWarehouse/AcceptToWarehouse';

// Packages - Void waybill
import VoidWaybill from '../pages/voidWaybill/VoidWaybill';

// Packages - Claims
import Claims from '../pages/claims/Claims';

// View Billing Upload Details
import ViewBillingUploadDetails from '../pages/billing/ViewBillingUploadDetails';
import ViewRemittanceUpload from '../pages/cashierRemittance/ViewRemittanceUpload';
// Packages - Waybill
import WaybillList from '../pages/waybill/WaybillList';
import WaybillCreate from '../pages/waybill/WaybillCreate';
import WaybillEdit from '../pages/waybill/WaybillEdit';
// import WaybillView from '../pages/waybill/WaybillView';
import WaybillUpload from '../pages/waybill/WaybillUpload';

// Tools - Package Group Checker
import GroupChecker from '../pages/tools/GroupChecker';
import DimweightUpload from '../pages/tools/DimweightUpload';
import DimweightUpdate from '../pages/tools/DimweightUpdate';
import StatusUpdater from '../pages/tools/StatusUpdater';

// Item tracker
import TrackItem from '../pages/itemTracker/TrackItem';
import ItemList from '../pages/itemTracker/ItemList';
import ItemListView from '../pages/itemTracker/ItemListView';
import ItemListViewByReference from '../pages/itemTracker/ItemListViewByReference';
import BarcodeGenerator from '../pages/itemTracker/Barcode';

export default [
  {
    key: '/',
    roles: roles.packageWaybillList,
    exact: true,
    path: '/',
    component: WaybillList,
  },
  {
    roles: roles.billingDashboard,
    key: '/dashboard',
    exact: true,
    path: '/dashboard',
    component: Dashboard,
  },
  {
    roles: roles.dashboard,
    key: '/kpi',
    exact: true,
    path: '/kpi',
    component: KpiDashboard,
  },
  {
    roles: roles.dashboard,
    key: '/backlogs',
    exact: true,
    path: '/backlogs',
    component: Backlogs,
  },
  {
    roles: roles.dashboard,
    key: '/volume',
    exact: true,
    path: '/volume',
    component: VolumeTrend,
  },
  {
    roles: roles.dashboard,
    key: '/pickup/calendar',
    exact: true,
    path: '/pickup/calendar',
    component: PickupCalendar,
  },
  {
    roles: roles.dashboard,
    key: '/courier/performance',
    exact: true,
    path: '/courier/performance',
    component: FleetPerformance,
  },
  {
    key: '/shipper-driver',
    roles: roles.all,
    exact: true,
    path: '/shipper-driver',
    component: ShipperDriver,
  },
  {
    key: '/item-tagging',
    roles: roles.all,
    exact: true,
    path: '/item-tagging',
    component: TrackItem,
  },
  {
    key: '/items/list',
    roles: roles.itemsViews,
    exact: true,
    path: '/items/list',
    component: ItemList,
  },
  {
    key: '/items/:reference/:item_id',
    roles: roles.itemsViews,
    exact: true,
    path: '/items/:reference/:item_id',
    component: ItemListView,
  },
  {
    key: '/items/:reference',
    roles: roles.itemsViews,
    exact: true,
    path: '/items/:reference',
    component: ItemListViewByReference,
  },
  {
    key: '/barcode',
    roles: roles.itemsOperations,
    exact: true,
    path: '/barcode',
    component: BarcodeGenerator,
  },
  {
    key: '/dispatch/:idNumber',
    roles: roles.dispatchViews,
    exact: true,
    path: '/dispatch/:idNumber',
    component: DispatchSearch,
  },
  {
    key: '/billing/details/:id',
    roles: roles.billingViews,
    exact: true,
    path: '/billing/details/:id',
    component: ViewBillingUploadDetails,
  },
  {
    key: '/billing/unbilled-list',
    roles: roles.billingViews,
    exact: true,
    path: '/billing/unbilled-list',
    component: BillingUnbilledList,
  },
  {
    key: '/billing/upload',
    roles: roles.billingOperations,
    exact: true,
    path: '/billing/upload',
    component: BillingUpload,
  },
  {
    key: '/billing/list',
    roles: roles.billingViews,
    exact: true,
    path: '/billing/list',
    component: BillingList,
  },
  {
    key: '/billing/reports',
    roles: roles.billingViews,
    exact: true,
    path: '/billing/reports',
    component: BillingReports,
  },
  {
    key: '/billing/view/:id',
    exact: true,
    path: '/billing/view/:id',
    roles: roles.billingViews,
    component: BillingViews,
  },
  {
    key: '/monitoring/accounts',
    roles: roles.monitoring,
    exact: true,
    path: '/monitoring/accounts',
    component: MonitoringAccounts,
  },
  {
    key: '/monitoring/firstmile',
    roles: roles.monitoring,
    exact: true,
    path: '/monitoring/firstmile',
    component: MonitoringFirstMile,
  },
  {
    key: '/remittance/create/:id',
    roles: roles.dispatchOperations,
    exact: true,
    path: '/remittance/create/:id',
    component: CreateDispatch,
  },
  {
    key: '/cashier-remittance/details/:id',
    roles: roles.cashierRemittanceViews,
    exact: true,
    path: '/cashier-remittance/details/:id',
    component: ViewRemittanceUpload,
  },
  {
    key: '/cashier-remittance/unremitted-list',
    roles: roles.cashierRemittanceViews,
    exact: true,
    path: '/cashier-remittance/unremitted-list',
    component: CashierRemittanceUnremittedList,
  },
  {
    key: '/cashier-remittance/create',
    roles: roles.cashierRemittanceOperations,
    exact: true,
    path: '/cashier-remittance/create',
    component: CashierRemittanceCreate,
  },
  {
    key: '/cashier-remittance/list',
    roles: roles.cashierRemittanceViews,
    exact: true,
    path: '/cashier-remittance/list',
    component: CashierRemittanceList,
  },
  {
    key: '/cashier-remittance/remittance-reports',
    roles: roles.cashierRemittanceViews,
    exact: true,
    path: '/cashier-remittance/remittance-reports',
    component: CashierRemittanceReport,
  },
  {
    key: '/courier-remittance/create',
    roles: roles.courierRemittanceOperations,
    exact: true,
    path: '/courier-remittance/create',
    component: CourierRemittanceCreate,
  },
  {
    key: '/courier-remittance/list',
    roles: roles.courierRemittanceViews,
    exact: true,
    path: '/courier-remittance/list',
    component: CourierRemittanceList,
  },
  {
    key: '/courier-remittance/remitted-items',
    roles: roles.courierRemittanceViews,
    exact: true,
    path: '/courier-remittance/remitted-items',
    component: CourierRemittanceRemittedItems,
  },
  {
    key: '/courier-remittance/:id',
    roles: roles.courierRemittanceViews,
    exact: true,
    path: '/courier-remittance/:id',
    component: CourierRemittanceListViews,
  },
  {
    key: '/void-waybill',
    roles: roles.voidWaybill,
    exact: true,
    path: '/void-waybill',
    component: VoidWaybill,
  },
  {
    key: '/clients',
    roles: roles.clients,
    exact: true,
    path: '/clients',
    component: Clients,
  },
  {
    key: '/clients/status-map',
    roles: roles.admin,
    exact: true,
    path: '/clients/status-map',
    component: ClientsStatusMap,
  },
  {
    key: '/clients/:id/status-map',
    roles: roles.clientsStatusMapView,
    exact: true,
    path: '/clients/:id/status-map',
    component: ClientStatusMapView,
  },
  {
    key: '/outsource',
    roles: roles.admin,
    exact: true,
    path: '/outsource',
    component: Outsource,
  },
  {
    key: '/reports/dispatched-items',
    roles: roles.reportsItems,
    exact: true,
    path: '/reports/dispatched-items',
    component: ReportsDispatchedItems,
  },
  {
    key: '/reports/picked-items',
    roles: roles.reportsItems,
    exact: true,
    path: '/reports/picked-items',
    component: ReportsPickedItems,
  },
  {
    key: '/reports/items-for-pickup',
    roles: roles.reportsItems,
    exact: true,
    path: '/reports/items-for-pickup',
    component: ReportsItemsForPickup,
  },
  {
    key: '/reports/items-location',
    roles: roles.reportsItemsLocation,
    exact: true,
    path: '/reports/items-location',
    component: ReportsItemsLocation,
  },
  {
    key: '/reports/unremitted-items',
    roles: roles.reportsUnremittedItems,
    exact: true,
    path: '/reports/unremitted-items',
    component: ReportsUnremittedItems,
  },
  {
    key: '/reports/cashier-unremitted',
    roles: roles.reportsCashierUnremitted,
    exact: true,
    path: '/reports/cashier-unremitted',
    component: ReportsCashierUnremitted,
  },
  {
    key: '/repush',
    roles: roles.repush,
    exact: true,
    path: '/repush',
    component: Repush,
  },
  {
    key: '/user/create',
    roles: roles.admin,
    exact: true,
    path: '/user/create',
    component: UserCreate,
  },
  {
    key: '/user/edit/:id',
    roles: roles.admin,
    exact: true,
    path: '/user/edit/:id',
    component: UserEdit,
  },
  {
    key: '/user/list',
    roles: roles.usersViews,
    exact: true,
    path: '/user/list',
    component: UserList,
  },
  {
    key: '/vehicles',
    roles: roles.admin,
    exact: true,
    path: '/vehicles',
    component: Vehicles,
  },
  {
    key: '/accept-to-warehouse',
    roles: roles.acceptToWarehouse,
    exact: true,
    path: '/accept-to-warehouse',
    component: AcceptToWarehouse,
  },
  {
    key: '/customer-pickup/list',
    roles: roles.customerPickupViews,
    exact: true,
    path: '/customer-pickup/list',
    component: CustomerPickupList,
  },
  {
    key: '/customer-pickup/dispatch',
    roles: roles.customerPickupOperations,
    exact: true,
    path: '/customer-pickup/dispatch',
    component: CustomerPickupDispatch,
  },
  {
    key: '/customer-pickup/accept-by-courier',
    roles: roles.customerPickupTagging,
    exact: true,
    path: '/customer-pickup/accept-by-courier',
    component: CustomerPickupAcceptByCourier,
  },
  {
    key: '/customer-pickup/failed-pickup',
    roles: roles.customerPickupTagging,
    exact: true,
    path: '/customer-pickup/failed-pickup',
    component: CustomerPickupFailedPickup,
  },
  {
    key: '/customer-pickup/:id',
    roles: roles.customerPickupViews,
    exact: true,
    path: '/customer-pickup/:id',
    component: CustomerPickupView,
  },
  {
    key: '/customer-pickup/edit/:id',
    roles: roles.customerPickupOperations,
    exact: true,
    path: '/customer-pickup/edit/:id',
    component: CustomerPickupDispatchEdit,
  },

  {
    key: '/delivery/list',
    roles: roles.deliveryViews,
    exact: true,
    path: '/delivery/list',
    component: DeliveryList,
  },
  {
    key: '/delivery/pod-return',
    roles: roles.deliveryPODReturned,
    exact: true,
    path: '/delivery/pod-return',
    component: DeliveryDispatchPODReturned,
  },
  {
    key: '/delivery/delivered',
    roles: roles.deliveryTagging,
    exact: true,
    path: '/delivery/delivered',
    component: DeliveryDelivered,
  },
  {
    key: '/delivery/failed',
    roles: roles.deliveryTagging,
    exact: true,
    path: '/delivery/failed',
    component: DeliveryFailed,
  },
  {
    key: '/delivery/dispatch',
    roles: roles.deliveryOperations,
    exact: true,
    path: '/delivery/dispatch',
    component: DeliveryDispatch,
  },
  {
    key: '/delivery/edit/:id',
    roles: roles.superAdmin,
    exact: true,
    path: '/delivery/edit/:id',
    component: DeliveryDispatchEdit,
  },
  {
    key: '/delivery/:id',
    roles: roles.deliveryViews,
    exact: true,
    path: '/delivery/:id',
    component: DeliveryView,
  },
  {
    key: '/merchant-pickup/list',
    roles: roles.merchantPickupViews,
    exact: true,
    path: '/merchant-pickup/list',
    component: MerchantPickupList,
  },
  {
    key: '/merchant-pickup/transmittal/list',
    roles: roles.merchantPickupViews,
    exact: true,
    path: '/merchant-pickup/transmittal/list',
    component: MerchantPickupTransmittalList,
  },
  {
    key: '/merchant-pickup/transmittal/:id',
    roles: roles.merchantPickupViews,
    exact: true,
    path: '/merchant-pickup/transmittal/:id',
    component: MerchantPickupTransmittalView,
  },
  {
    key: '/merchant-pickup/failed-pickup',
    roles: roles.merchantPickupTagging,
    exact: true,
    path: '/merchant-pickup/failed-pickup',
    component: MerchantPickupFailedPickup,
  },
  {
    key: '/merchant-pickup/accept-by-courier',
    roles: roles.merchantPickupTagging,
    exact: true,
    path: '/merchant-pickup/accept-by-courier',
    component: MerchantPickupAcceptedByCourier,
  },
  {
    key: '/merchant-pickup/dispatch',
    roles: roles.merchantPickupOperations,
    exact: true,
    path: '/merchant-pickup/dispatch',
    component: MerchantPickupDispatch,
  },
  {
    key: '/merchant-pickup/multi-dispatch',
    roles: roles.merchantPickupOperations,
    exact: true,
    path: '/merchant-pickup/multi-dispatch',
    component: MerchantMultiPickupDispatch,
  },
  {
    key: '/merchant-pickup/refusal',
    roles: roles.merchantPickupTagging,
    exact: true,
    path: '/merchant-pickup/refusal',
    component: MerchantPickupRefusal,
  },
  {
    key: '/merchant-pickup/released',
    roles: roles.merchantPickupTagging,
    exact: true,
    path: '/merchant-pickup/released',
    component: MerchantPickupReleased,
  },
  {
    key: '/merchant-pickup/rejected',
    roles: roles.merchantPickupTagging,
    exact: true,
    path: '/merchant-pickup/rejected',
    component: MerchantPickupRejected,
  },
  {
    key: '/merchant-pickup/drop-off',
    roles: roles.merchantPickupTagging,
    exact: true,
    path: '/merchant-pickup/drop-off',
    component: MerchantPickupDropOff,
  },
  {
    key: '/merchant-pickup/:id',
    roles: roles.merchantPickupViews,
    exact: true,
    path: '/merchant-pickup/:id',
    component: MerchantPickupView,
  },
  {
    key: '/merchant-pickup/edit/:id',
    roles: roles.merchantPickupOperations,
    exact: true,
    path: '/merchant-pickup/edit/:id',
    component: MerchantPickupDispatchEdit,
  },
  {
    key: '/pickup/list',
    roles: roles.shipperPickupViews,
    exact: true,
    path: '/pickup/list',
    component: PickupList,
  },
  {
    key: '/pickup/dispatch',
    roles: roles.shipperPickupOperations,
    exact: true,
    path: '/pickup/dispatch',
    component: PickupDispatch,
  },
  {
    key: '/pickup/:id',
    roles: roles.shipperPickupViews,
    exact: true,
    path: '/pickup/:id',
    component: PickupView,
  },
  {
    key: '/pickup/edit/:id',
    roles: roles.superAdmin,
    exact: true,
    path: '/pickup/edit/:id',
    component: PickupDispatchEdit,
  },

  {
    key: '/rts/list',
    roles: roles.RTSViews,
    exact: true,
    path: '/rts/list',
    component: RTSList,
  },
  {
    key: '/rts/dispatch',
    roles: roles.RTSOperations,
    exact: true,
    path: '/rts/dispatch',
    component: RTSDispatch,
  },
  {
    key: '/rts-transfer/dispatch',
    roles: roles.RTSOperations,
    exact: true,
    path: '/rts-transfer/dispatch',
    component: RTSTransferDispatch,
  },
  {
    key: '/rts/returned',
    roles: roles.RTSTagging,
    exact: true,
    path: '/rts/returned',
    component: RTSReturned,
  },
  {
    key: '/rts/refused',
    roles: roles.RTSTagging,
    exact: true,
    path: '/rts/refused',
    component: RTSRefused,
  },
  {
    key: '/rts/pod-return',
    roles: roles.RTSTagging,
    exact: true,
    path: '/rts/pod-return',
    component: RTSPODReturned,
  },
  {
    key: '/rts/:id',
    roles: roles.RTSViews,
    exact: true,
    path: '/rts/:id',
    component: RTSView,
  },
  {
    key: '/rts/edit/:id',
    roles: roles.superAdmin,
    exact: true,
    path: '/rts/edit/:id',
    component: RTSDispatchEdit,
  },
  {
    key: '/claims',
    roles: roles.claims,
    exact: true,
    path: '/claims',
    component: Claims,
  },
  {
    key: '/transfer/list',
    roles: roles.transferViews,
    exact: true,
    path: '/transfer/list',
    component: TransferList,
  },
  {
    key: '/transfer/dispatch',
    roles: roles.transferOperations,
    exact: true,
    path: '/transfer/dispatch',
    component: TransferDispatch,
  },
  {
    key: '/transfer/:id',
    roles: roles.transferViews,
    exact: true,
    path: '/transfer/:id',
    component: TransferView,
  },
  {
    key: '/transfer/edit/:id',
    roles: roles.superAdmin,
    exact: true,
    path: '/transfer/edit/:id',
    component: TransferDispatchEdit,
  },
  {
    key: '/rtm/edit/:id',
    roles: roles.superAdmin,
    exact: true,
    path: '/rtm/edit/:id',
    component: RTMDispatchEdit,
  },
  {
    key: '/rtm/list',
    roles: roles.RTMViews,
    exact: true,
    path: '/rtm/list',
    component: RTMList,
  },
  {
    key: '/rtm/refused',
    roles: roles.RTMTagging,
    exact: true,
    path: '/rtm/refused',
    component: RTMRefused,
  },
  {
    key: '/rtm/returned',
    roles: roles.RTMTagging,
    exact: true,
    path: '/rtm/returned',
    component: RTMReturned,
  },
  {
    key: '/rtm/dispatch',
    roles: roles.RTMOperations,
    exact: true,
    path: '/rtm/dispatch',
    component: RTMDispatch,
  },
  {
    key: '/rtm/:id',
    roles: roles.RTMViews,
    exact: true,
    path: '/rtm/:id',
    component: RTMView,
  },
  {
    key: '/waybill/list',
    roles: roles.packageWaybillList,
    exact: true,
    path: '/waybill/list',
    component: WaybillList,
  },
  {
    key: '/waybill/create',
    roles: roles.waybillOperations,
    exact: true,
    path: '/waybill/create',
    component: WaybillCreate,
  },
  {
    key: '/waybill/upload',
    roles: roles.waybillOperations,
    exact: true,
    path: '/waybill/upload',
    component: WaybillUpload,
  },
  {
    key: '/waybill/:tnumber',
    roles: roles.all,
    exact: true,
    path: '/waybill/:tnumber',
    component: WaybillComponent,
  },
  // {
  //   key: '/waybill/:id',
  //   roles: roles.waybill,
  //   exact: true,
  //   path: '/waybill/:id',
  //   component: WaybillView,
  // },
  // Temporary Disabled (EDIT WAYBILL)
  {
    key: '/waybill/edit/:id',
    roles: roles.waybillEditor,
    exact: true,
    path: '/waybill/edit/:id',
    component: WaybillEdit,
  },
  {
    key: '/tools/package-group-checker',
    roles: roles.toolsGroupChecker,
    exact: true,
    path: '/tools/package-group-checker',
    component: GroupChecker,
  },
  {
    key: '/tools/dimweight-upload',
    roles: roles.toolsDimweightUpload,
    exact: true,
    path: '/tools/dimweight-upload',
    component: DimweightUpload,
  },
  {
    key: '/tools/dimweight-update',
    roles: roles.toolsDimweightUpload,
    exact: true,
    path: '/tools/dimweight-update',
    component: DimweightUpdate,
  },
  {
    key: '/tools/status-updater',
    roles: roles.toolsStatusUpdater,
    exact: true,
    path: '/tools/status-updater',
    component: StatusUpdater,
  },
  {
    key: '/port-cluster',
    roles: roles.portClusterViews,
    exact: true,
    path: '/port-cluster',
    component: PortClusterForm,
  },
  {
    key: '/port-cluster/create',
    roles: roles.portClusterOperations,
    exact: true,
    path: '/port-cluster/create',
    component: PortClusterCreate,
  },
  {
    key: '/port-cluster/upload',
    roles: roles.portClusterOperations,
    exact: true,
    path: '/port-cluster/upload',
    component: PortClusterUpload,
  },
  {
    key: '/404',
    roles: roles.all,
    exact: true,
    path: '/404',
    component: ContentMissing,
  },
];
