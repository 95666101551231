import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import fileDownload from 'js-file-download';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import Select from '../../components/Select';
import {
 Col, Form, Button, DatePicker, Icon , Modal , Result
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExport, faSearch } from '@fortawesome/free-solid-svg-icons';
import { fetchOptions, fetchOptionsLike } from '../ducks';

import { rubixpickOptions } from '../../components/options/ducks';
import { waybill } from '../../components/waybill/ducks';
import { bList } from './ducks';
import { billingListView } from '../ducks';
import Row from '../../components/Row';
import PageHeader from '../../components/PageHeader';
import ReactTable from '../../components/ServerSideTable';
import Card from '../../components/Card';
import Container from '../../components/Container';
import ReactSelect from '../../components/Select';

export class UnbilledList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      hasVisible: false,
      status: [],
      dateReferenceOptions: [
        {
          label: 'Handover Date',
          value: 'handover_date',
        },
        {
          label: 'First Delivery Date',
          value: 'first_delivery_date',
        },
        {
          label: 'Last Delivery Date',
          value: 'last_delivery_date',
        },
        {
          label: 'Date Created',
          value: 'created_at',
        },
        {
          label: 'Date Updated',
          value: 'updated_at',
        },
      ],
      handoverDate: [],
      loading: true,
      userData: {
        rows: [],
        pages: 0,
        total: 0,
      },
      options:{
        client: []
      },
      payloadReq: {
        export: false,
        page: 0,
        pageSize: 10,
        sorted: [],
      },
      tableFilter: [],
      searchFilter: [],
    };

    this.onFetchDataFunc = this.onFetchDataFunc.bind(this);
    this.selectHandler = this.selectHandler.bind(this);
    this.dateDatepickerHandler = this.dateDatepickerHandler.bind(this);

  }
  selectHandler(type, e) {
    this.setState(prevState => ({
      advancedFilter: {
        ...prevState.advancedFilter,
        [type]: e,
      },
    }));
  }
  searchOption(type, e) {
    const { doFetchOptionsLike } = this.props;

    this.setState({
      isLoading: true,
    });

    doFetchOptionsLike(type, e).then((action) => {
      this.setState(prevState => ({
        client: action.payload.data,
        isLoading: false,
      }));
    });
  }
  componentDidMount() {
 
    const { doFetchOptions, reduxExportTemplate } = this.props;

    const { doRubixpickOptions } = this.props;
    doRubixpickOptions('client').then(res => this.setState({
      client: res.payload.data,

    }));
    doRubixpickOptions('status').then(res => this.setState({
      status: res.payload.data,

    }));
  }

  onFetchDataFunc(state) {
    this.setState({
      loading: true,
    });
    // console.log(state);
    const { searchFilter } = this.state;
    const { billingNonFilterUnbilledList } = this.props;
    const paramsReq = {
      export: false,
      page: state.page,
      pageSize: state.pageSize,
      filter: state.filtered,
      sorted: state.sorted,
    };
    if (searchFilter.length) {
      paramsReq.filter = paramsReq.filter.concat(searchFilter);
    }
    billingNonFilterUnbilledList('unbilled-list', paramsReq).then(res => this.setState({
      loading: false,
      userData: {
        rows: res.payload.data.rows,
        pages: res.payload.data.pages,
        total: res.payload.data.total,
      },
      payloadReq: paramsReq,
      tableFilter: state.filtered,
    }));
  }
  dateDatepickerHandler(type, e) {
    const dateReferenceSelection = {
      handover_date: null,
      first_delivery_date: null,
      last_delivery_date: null,
      created_at: null,
      updated_at: null,
    };
    const {
      datepickerValueState: datePickerSub,
      dateRefernceFilterState: dateReferenceSub,
    } = this.state;
    let datepickerValue = datePickerSub;
    let dateRefernceFilter = !dateReferenceSub ? 'handover_date' : dateReferenceSub;
    if (type === 'date_reference') {
      dateRefernceFilter = e;
    }
    if (type === 'dateRange') {
      datepickerValue = `${format(e[0], 'YYYY-MM-DD HH:mm')}|${format(e[1], 'YYYY-MM-DD HH:mm')}`;
    }
    if (datePickerSub) {
      this.setState(prevState => ({
        advancedFilter: {
          ...prevState.advancedFilter,
          ...dateReferenceSelection,
          [dateRefernceFilter !== undefined ? dateRefernceFilter : 'handover_date']: datePickerSub !== 'Invalid Date|Invalid Date' ? datePickerSub : '|',
        },
        datepickerValueState: datePickerSub,
        dateRefernceFilterState: dateRefernceFilter,
      }));
    }
    this.setState(prevState => ({
      advancedFilter: {
        ...prevState.advancedFilter,
        ...dateReferenceSelection,
        [dateRefernceFilter !== undefined ? dateRefernceFilter : 'handover_date']: datepickerValue !== 'Invalid Date|Invalid Date' ? datepickerValue : '|',
      },
      datepickerValueState: datepickerValue,
      dateRefernceFilterState: dateRefernceFilter,
    }));
  }
  getEditAccountHandler(e) {
    const { waybillComponent, history } = this.props;
    waybillComponent(e.tracking_number).then(() => history.push(`/waybill/${e.tracking_number}`),);
    // console.log(e);
  }

  submitHandler() {
    const { handoverDate, payloadReq, tableFilter } = this.state;
    const { billingNonFilterUnbilledList, form } = this.props;
    const { validateFields } = form;
    validateFields((err, values) => {
      if(handoverDate.length > 0 && values.date_reference != undefined){
        handoverDate[0].id = values.date_reference;
      }
      if (!err) {
        this.setState({
          loading: true,
        });
        const paramsReq = {
          export: false,
          page: payloadReq.page,
          pageSize: payloadReq.pageSize,
          filter: values.client
            ? [...handoverDate, { id: 'client_id', value: values.client },
              values.status ? {id: 'status', value: values.status} : null]
            : [...handoverDate, values.status ? {id: 'status', value: values.status} : null],
          sorted: payloadReq.sorted,
        };
        if (tableFilter.length) {
          paramsReq.filter = paramsReq.filter.concat(tableFilter);
        }
        billingNonFilterUnbilledList('unbilled-list', paramsReq).then(res => this.setState({
          loading: false,
          userData: {
            rows: res.payload.data.rows,
            pages: res.payload.data.pages,
            total: res.payload.data.total,
          },
          payloadReq: paramsReq,
          searchFilter: values.client
          ? [...handoverDate, { id: 'client_id', value: values.client },
            values.status ? {id: 'status', value: values.status} : null]
          : [...handoverDate, values.status ? {id: 'status', value: values.status} : null],
        }));
      
      }
    });
  }

  submitHandlerExport() {
    if(this.state.userData.total <= 25000){
      const { payloadReq, handoverDate } = this.state;
    const { billingNonFilterUnbilledList, form } = this.props;
    const { validateFields } = form;
    validateFields((err, values) => {
      if (!err) {
        const paramsReq = {
          export: true,
          page: payloadReq.page,
          pageSize: payloadReq.pageSize,
          filter: values.client
          ? [...handoverDate, { id: 'client_id', value: values.client },
            values.status ? {id: 'status', value: values.status} : null]
          : [...handoverDate, values.status ? {id: 'status', value: values.status} : null],
          sorted: payloadReq.sorted,
        };
        this.setState({
          loading: true,
          payloadReq: paramsReq,
        });
        billingNonFilterUnbilledList('unbilled-list', paramsReq).then((res) => {
          const newBlob = new Blob([res.payload.data]);
          fileDownload(newBlob, 'Unbilled-list.csv');
          this.setState({
            loading: false,
          });
        });
      }
    });
    }else{
      this.setState({hasVisible: true});
    }
  }

  dateHandler(dateStrings) {
    const newDateOne = dateStrings[0];
    const newDateTwo = dateStrings[1];
    const formatDateOne = format(newDateOne, 'YYYY-MM-DD HH:mm:ss');
    const formatDateTwo = format(newDateTwo, 'YYYY-MM-DD HH:mm:ss');
    const dateForm = `${formatDateOne}|${formatDateTwo}`;
    
    this.setState({
      handoverDate: [
        {
          id: 'handover_date',
          value: formatDateOne !== 'Invalid Date' ? dateForm : '',
        },
      ],
    });
  }

  render() {
    const { loading, userData, client, status,isLoading, dateReferenceOptions, hasVisible } = this.state;
    const { form } = this.props;
    const { getFieldDecorator } = form;

    const customFilterOption = (inputValue, option) => {
      return option.props.children.toString().toLowerCase().includes(inputValue.toString().toLowerCase());
    };
    const breadCrumbs = [
      {
        breadcrumbName: 'Billing',
      },
      {
        breadcrumbName: 'Unbilled List',
      },
    ];
    return (
      <div className="UnbilledList">
        <PageHeader title="UNBILLED LIST" routes={breadCrumbs} />
        <Container>
          <Card>
            <Row>
              <Col xs={24} sm={12} lg={12}>
                <Form.Item label="Client">
                {getFieldDecorator('client', {
                    rules: [{ required: false, message: 'Please insert value' }],
                  })(
                <ReactSelect
                      // mode="multiple"
                      // onChange={(e) => { this.selectHandler('client', e); }}
                      options={client}
                      // allowClear
                      // loading={isLoading}
                      // onSearch={e => this.searchOption('client', e)}
                      filterOption={customFilterOption}
                      placeholder="Type to search for client"
                      // showSearch
                      style={{ width: '100%' }}
                    />)
                  }
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} lg={12}>
                <Form.Item label="Status">
                  {getFieldDecorator('status', {
                    rules: [{ required: false, message: 'Please insert value' }],
                  })(
                    <ReactSelect
                      mode="multiple"
                      style={{ width: '100%' }}
                      placeholder="Select Status..."
                      options={status}
                    />,
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} lg={12}>
              <Form.Item label="Date Reference">
              {getFieldDecorator('date_reference', {
                    rules: [{ required: false, message: 'Please insert value' }],
                  })(
                    <ReactSelect
                      style={{ width: '100%' }}
                      placeholder="Select Status..."
                      options={dateReferenceOptions}
                    />,
                  )}
                </Form.Item></Col>
              <Col xs={24} sm={12} lg={12}>
                <Form.Item label="Date Range">
                  <DatePicker.RangePicker
                    showTime={{
                      hideDisabledOptions: true,
                      defaultValue: [
                        moment('00:00', 'HH:mm'),
                        moment('00:00', 'HH:mm'),
                      ],
                    }}
                    style={{ width: '100%' }}
                    onChange={e => this.dateHandler(e)}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Button onClick={() => this.submitHandler()} type="primary" block>
              <Icon viewBox="0 0 1024 1024">
                <FontAwesomeIcon icon={faSearch} fixedWidth />
              </Icon>
              Apply
            </Button>
          </Card>
          <Select />
          <Card
            title={(
              <Button onClick={() => this.submitHandlerExport()} type="danger">
                <Icon viewBox="0 0 1024 1024">
                  <FontAwesomeIcon icon={faFileExport} fixedWidth />
                </Icon>
                Export
              </Button>
            )}
            extra={`Total: ${userData.total
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
          >
            <ReactTable
              data={userData.rows}
              pages={userData.pages}
              columns={[
                {
                  Header: 'Handover Date',
                  accessor: 'handover_date',
                },
                {
                  Header: 'Date Created',
                  accessor: 'created_at',
                },
                {
                  Header: 'Tracking Number',
                  accessor: 'tracking_number',
                },
                {
                  Header: 'Status',
                  accessor: 'status',
                },
                {
                  Header: 'CBM',
                  accessor: 'package_cbm',
                },
                {
                  Header: 'Client',
                  accessor: 'client_name',
                },
                {
                  Header: 'Package Type',
                  accessor: 'package_type',
                },
                {
                  Header: 'Length',
                  accessor: 'length',
                },
                {
                  Header: 'Width',
                  accessor: 'width',
                },
                {
                  Header: 'Height',
                  accessor: 'height',
                },
                {
                  Header: 'Weight',
                  accessor: 'weight',
                },
                {
                  Header: 'Options',
                  sortable: false,
                  filterable: false,
                  Cell: row => (
                    <center>
                      <Button
                        type="link"
                        onClick={() => this.getEditAccountHandler(row.original)}
                      >
                        <Icon type="eye" />
                        View
                      </Button>
                    </center>
                  ),
                },
              ]}
              loadingText="Fetching unbilled list..."
              loading={loading}
              onFetchData={this.onFetchDataFunc}
            />
          </Card>
        </Container>
        <Modal
          title="Error!"
          visible={hasVisible}
          footer={(
            /* eslint-disable */
            <React.Fragment>
              <Button type="primary" onClick={() => this.setState({hasVisible: false})}>
                Confirm
              </Button>
            </React.Fragment>
          )}
        >
          <Result
            status="error"
            title="Exportable rows must be more than 0 and less than or equal to 25,000. Please reduce your date range"
          />
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
});
const mapDispatchToProps = {
  ubList: bList,
  billingNonFilterUnbilledList: billingListView,
  waybillComponent: waybill,
  doRubixpickOptions: rubixpickOptions,
  doFetchOptions: fetchOptions,
  doFetchOptionsLike: fetchOptionsLike,
};

UnbilledList.propTypes = {
  billingNonFilterUnbilledList: PropTypes.func.isRequired,
  waybillComponent: PropTypes.func.isRequired,
  history: PropTypes.oneOfType([PropTypes.object]).isRequired,
  doRubixpickOptions: PropTypes.func.isRequired,
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  doFetchOptions: PropTypes.func.isRequired,
  doFetchOptionsLike: PropTypes.func.isRequired,

};


const WrappedUnbilledList = Form.create({ name: 'Register' })(UnbilledList);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WrappedUnbilledList);
