import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { rubixpickOptions } from '../../components/options/ducks';
import {
  Input, Icon, Button, Col, Form, message, Popconfirm, Spin,

} from 'antd';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSearch,
  faPlus,
  faTimes,
  faSave,
  faEdit,
} from '@fortawesome/free-solid-svg-icons';
import PageHeader from '../../components/PageHeader';
import Container from '../../components/Container';
import Card from '../../components/Card';
import Row from '../../components/Row';
import Spacer from '../../components/Spacer';
import ReactTable from '../../components/ServerSideTable';
import Select from '../../components/Select';
import { fetchShipperDriverList, deleteShipperDriver, createShipperDriver } from './ducks';
import { fetchOptions, fetchOptionsLike } from '../ducks';

export class Client extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      loading: false,
      isFetchingSelect: false,
      isFetchingCourier: false,
      advancedFilter: {
        client: null,
        shipper: null,

      },
      isFetchingClient: false,
      selectedClient: null,
      shipperDriver: [],
      client: [],
      merchant: [],
      courier: [],
      merchantToDisplay:[],
      shipperDriverList: {
        rows: [],
        pages: 0,
        total: 0,
      },
      currentTable: {
        page: null,
        pageSize: null,
        filter: [],
        sorted: [],
      },
      isLoading: false,
      editedClient: null,
      clientPrefixOptions: [],
    };

    this.onFetchData = this.onFetchData.bind(this);
    this.formCreate = this.formCreate.bind(this);
    this.setToEdit = this.setToEdit.bind(this);
    this.cancelEdit = this.cancelEdit.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.formUpdate = this.formUpdate.bind(this);
    this.clientSelected = this.clientSelected.bind(this);

  }
  searchOption(type, e) {
    const { doFetchOptionsLike } = this.props;

    this.setState({
      isFetching: true,
    });

    doFetchOptionsLike('merchant', e, `&client_id=${this.state.selectedClient}`).then((action) => {
      this.setState(prevState => ({
        merchant: action.payload.data.slice(0, 50),
        isFetching: false,
      }));
    });
  }
  componentDidMount() {
    const { doRubixpickOptions } = this.props;
    this.setState({isFetchingClient: true, isFetchingCourier: true});
    doRubixpickOptions('client').then(res => this.setState({
      client: res.payload.data,
      isFetchingClient: false
    }));
    doRubixpickOptions('courier').then(res => this.setState({
      courier: res.payload.data,
      isFetchingCourier: false
    }));
    
    
  }

  // eslint-disable-next-line no-unused-vars
  onFetchData(state, instance) {
    const { dofetchShipperDriverList } = this.props;

    const params = {
      page: state.page,
      pageSize: state.pageSize,
      filter: state.filtered,
      sorted: state.sorted,
    };

    this.setState({
      isLoading: true,
      currentTable: params,
    });

    dofetchShipperDriverList(params).then((action) => {
      this.setState({
        shipperDriverList: {
          rows: action.payload.data.rows,
          pages: action.payload.data.pages,
          total: action.payload.data.total,
        },
        isLoading: false,
      });
    });
  }

  onEdit(cellInfo) {
    const { shipperDriverList, editedClient, clientPrefixOptions } = this.state;
    const { form } = this.props;
    const { getFieldDecorator } = form;

    if (editedClient === cellInfo.original.client_id) {
      if (cellInfo.column.id === 'package_privacy') {
        return (
          <Form.Item style={{ margin: '0px' }}>
            {getFieldDecorator(`${cellInfo.column.id}_update`, {
              rules: [{ required: true, message: `${cellInfo.column.Header} is required.` }],
              initialValue: shipperDriverList.rows[cellInfo.index][cellInfo.column.id],
            })(<Select
              allowClear={false}
              options={[
                {
                  label: 'Private',
                  value: 'private',
                },
                {
                  label: 'Public',
                  value: 'public',
                },
              ]}
            />)}
          </Form.Item>
        );
      } if (cellInfo.column.id === 'client_prefixes') {
        return (
          <Form.Item style={{ margin: '0px' }}>
            {getFieldDecorator(`${cellInfo.column.id}_update`, {
              rules: [
                { required: true, message: 'Client prefixes cannot be blank.' },
              ],
              initialValue: JSON.parse(shipperDriverList.rows[cellInfo.index][cellInfo.column.id]),
            })(<Select
              options={clientPrefixOptions}
              mode="multiple"
              placeholder="Select client prefixes"
              autoComplete="off"
            />)}
          </Form.Item>
        );
      }
      return (
        <Form.Item style={{ margin: '0px' }}>
          {getFieldDecorator(`${cellInfo.column.id}_update`, {
            rules: [
              {
                required: true,
                message: `${cellInfo.column.Header} cannot be blank.`,
              },
            ],
            initialValue: shipperDriverList.rows[cellInfo.index][cellInfo.column.id],
          })(
            <Input
              placeholder={`Enter ${cellInfo.column.Header}`}
              autoComplete="off"
            />,
          )}
        </Form.Item>
      );
    }

    return (
      <React.Fragment>
        {shipperDriverList.rows[cellInfo.index][cellInfo.column.id]}
      </React.Fragment>
    );
  }

  setToEdit(vehicleId) {
    this.setState({
      editedClient: vehicleId,
    });
  }

  cancelEdit() {
    this.setState({
      editedClient: null,
    });
  }

  formUpdate(e) {
    
  }

  formCreate(e) {
    e.preventDefault();
    const { doCreateShipperDriver, form } = this.props;
    const { validateFields } = form;

    validateFields(
      ['client','shipper_id','driver_id'],
      (err, values) => {
        if (!err) {
          doCreateShipperDriver(values)
            .then(() => {
              const { currentTable } = this.state;

              const params = {
                page: currentTable.page,
                pageSize: currentTable.pageSize,
                filtered: currentTable.filter,
                sorted: currentTable.sorted,
              };

              message.success('Successfully created shipper driver!');
              this.onFetchData(params);
              form.resetFields();
            })
            .catch((action) => {
              let errorMessage;
              if (action.error.response.status === 400) {
                errorMessage = action.error.response.data.message;
              } else {
                errorMessage = 'Something went wrong, please try again later.';
              }
              message.error(errorMessage, 3);
            });
        }
      },
    );
  }
  confirmRemove(id) {
    const { doDeleteShipperDriver } = this.props;
    doDeleteShipperDriver(id)
      .then(() => {
        const { currentTable } = this.state;

              const params = {
                page: currentTable.page,
                pageSize: currentTable.pageSize,
                filtered: currentTable.filter,
                sorted: currentTable.sorted,
              };
        this.onFetchData(params);
        message.success('Successfully deleted data!');
      })
      .catch((e) => {
        message.error('Something went wrong, please try again later.');
      });
    // const { trackingNumberRows } = this.state;
    // const filteredTrackingNumber = trackingNumberRows.filter(
    //   data => data.tracking_number !== trackingNumber,
    // );

    // this.setState({
    //   trackingNumberRows: filteredTrackingNumber,
    // });

  }
  submitHandler(){
    const { form } = this.props;
    const { validateFields } = form;
    validateFields((err,values) => {
      const { doRubixpickOptions } = this.props;
      doRubixpickOptions('shipper-driver',`&client_id=${values.client}&shipper_id=${values.shipper_id}`).then(res => this.setState({
        client: res.payload.data,
      }));

    });
  }
  clientSelected(clientId){
    this.setState({selectedClient: clientId, isFetching: true});
   const { doRubixpickOptions, form } = this.props;
   doRubixpickOptions('merchant',`&client_id=${clientId}`).then(res =>{ 
    
    this.setState({
      isFetching: false,
      merchant: res.payload.data.slice(0,50),
  });
  form.setFieldsValue(
    {
      shipper_id: undefined,
    },
  );
});
}

  render() {
    const {
      shipperDriverList, merchant ,client, selectedClient, isFetching,loading,courier,isFetchingClient, isFetchingCourier
    } = this.state;
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const customFilterOption = (inputValue, option) => {
      return option.props.children.toString().toLowerCase().includes(inputValue.toString().toLowerCase());
    };
    // const customFilterOptionMerchant = (inputValue, option) => {
    //   return option.props.children.toString().toLowerCase().includes(inputValue.toString().toLowerCase());
    // };
    const breadCrumbs = [
      {
        breadcrumbName: 'Admin',
      },
      {
        breadcrumbName: 'Shipper Driver',
      },
    ];
    return (  
      <div className="Client">
        <PageHeader title="Shipper Driver" routes={breadCrumbs} />
        <Container>
        <Form onSubmit={this.formCreate}>
          <Card title="Shipper Driver Create">
          <Row>
          <Col xs={24} sm={12} lg={8}>
          <Form.Item label="Client">
                {getFieldDecorator('client', {
                    rules: [{ required: true, message: 'Please insert value' }],
                  })(
                <Select
                      // mode="multiple"
                      // onChange={(e) => { this.selectHandler('client', e); }}
                      options={client}
                      // allowClear
                      loading={isFetchingClient}
                      // onSearch={e => this.searchOption('client', e)}
                      onChange={this.clientSelected}
                      value={selectedClient}
                      filterOption={customFilterOption}
                      placeholder="Type to search for client"
                      // showSearch
                      style={{ width: '100%' }}
                    />)
                  }
                </Form.Item>  
                </Col>  
                <Col xs={24} sm={12} lg={8}>  
                <Form.Item label="Merchant">  
                    {getFieldDecorator('shipper_id', {
                      rules: [
                        {
                          required: true,
                          message: 'Merchant cannot be blank.',
                        },
                      ],
                    })(
                      <Select
                      allowClear
                      options={merchant}
                      loading={isFetching}
                      onSearch={e => this.searchOption('merchant', e)}
                      filterOption={false}
                      placeholder="Type to search for desired merchant"
                      name="shipper_id"
                      showSearch
                        style={{ width: '100%' }}

                      />,
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={8}>
                <Form.Item label="Driver">
                    {getFieldDecorator('driver_id', {
                      rules: [
                        {
                          required: true,
                          message: 'Driver cannot be blank.',
                        },
                      ],
                    })(
                      <Select
                      allowClear
                      loading= {isFetchingCourier}
                      options={courier}
                      filterOption={customFilterOption}
                      placeholder="Type to search for desired driver"
                      name="driver_id"
                      showSearch
                        style={{ width: '100%' }}

                      />,
                    )}
                  </Form.Item>
                </Col>
          </Row>
                  <Button type="primary" htmlType="submit" block>
                    <Icon viewBox="0 0 1024 1024">
                      <FontAwesomeIcon icon={faPlus} fixedWidth />
                    </Icon>
                    Create
                  </Button>
            <Card
            title="Shipper Driver List"
              extra={`Total: ${shipperDriverList.total
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
          >
          </Card>
            <ReactTable
              data={shipperDriverList.rows}
              pages={shipperDriverList.pages}
              columns={[
                {
                  Header: 'Merchant',
                  accessor: 'shipper_name',
                },
                {
                  Header: 'Client',
                  accessor: 'client_name',
                },
                {
                  Header: 'Driver',
                  accessor: 'driver_name',  
                },
                {
                  Header: 'Options',
                  sortable: false,
                  filterable: false,
                  Cell: data => (
                    <React.Fragment>
            <Popconfirm
              placement="leftBottom"
              title={`Are you sure you want to delete this from the list?`}
              okText="Yes"
              onConfirm={() => this.confirmRemove(data.row._original.id)}
              cancelText="No"
            >
              <Button type="link" block>
                <Icon viewBox="0 0 1024 1024">
                  <FontAwesomeIcon icon={faTrash} fixedWidth />
                </Icon>
                Remove
              </Button>
            </Popconfirm>
          </React.Fragment>
        ),
                },
              ]}
              loadingText="Fetching unbilled list..."
              loading={loading}
              onFetchData={this.onFetchData}
              />
          </Card>
        </Form>
          <Spacer />
          
        </Container>
      </div>
    );
  }
}

Client.propTypes = {
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  dofetchShipperDriverList: PropTypes.func.isRequired,
  doCreateShipperDriver: PropTypes.func.isRequired,
  doUpdateClient: PropTypes.func.isRequired,
  doFetchOptions: PropTypes.func.isRequired,
  doFetchOptionsLike: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dofetchShipperDriverList: fetchShipperDriverList,
  doFetchOptions: fetchOptions,
  doFetchOptionsLike: fetchOptionsLike,
  doRubixpickOptions: rubixpickOptions,
  doDeleteShipperDriver: deleteShipperDriver,
  doCreateShipperDriver: createShipperDriver
};
const WrappedClient = Form.create({ name: 'Client' })(Client);

export default connect(null, mapDispatchToProps)(WrappedClient);
