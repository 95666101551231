import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Form,
  Col,
  Input,
  Icon,
  Button,
  message,
  Result,
  Modal,
  Spin,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import PageHeader from '../../components/PageHeader';
import Container from '../../components/Container';
import Card from '../../components/Card';
import Row from '../../components/Row';
import Select from '../../components/Select';
import {
  fetchOptionsLike, fetchOptions, createUser, validateUsername,
} from './ducks';

export class Create extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadSpin: false,
      options: {
        role: [],
        mobile_role: [],
        destination: [],
        client: [],
        outsource: [],
      },
      isFetching: false,
      password: null,
      isModalVisible: false,
      isValidating: false,
      roleValidate: false,
    };

    this.formSubmit = this.formSubmit.bind(this);
    this.searchOption = this.searchOption.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.validateUsername = this.validateUsername.bind(this);
    this.roleValidator = this.roleValidator.bind(this);
  }

  componentDidMount() {
    const { doFetchOptions } = this.props;

    const selectType = [
      'mobile_role',
      'role',
      'destination',
      'client',
      'outsource',
    ];

    Object.keys(selectType).forEach((key) => {
      doFetchOptions(selectType[key]).then((action) => {
        this.setState(prevState => ({
          options: {
            ...prevState.options,
            // eslint-disable-next-line no-constant-condition
            [selectType[key]]:
              selectType[key] === 'client'
                ? action.payload.data.slice(0, 50)
                : action.payload.data,
          },
        }));
      });
    });
  }

  validateUsername(rule, value, callback) {
    const { doValidateUsername } = this.props;
    if (value.length >= 5) {
      this.setState({
        isValidating: true,
      });

      doValidateUsername(value)
        .then(res => (res.payload.data
          ? callback('Username is already taken.') : callback()));
    }
  }

  searchOption(type, e) {
    const { doFetchOptionsLike } = this.props;

    this.setState({
      isValidating: true,
    });

    doFetchOptionsLike(type, e).then((action) => {
      this.setState(prevState => ({
        options: {
          ...prevState.options,
          [type]: action.payload.data.slice(0, 50),
        },
        isValidating: false,
      }));
    });
  }

  roleValidator(e) {
    if (e === 'courier') this.setState({ roleValidate: true, });
    else this.setState({ roleValidate: false, });
  }

  formSubmit(e) {
    e.preventDefault();
    const { form, doCreateUser } = this.props;
    const { validateFields } = form;

    validateFields((err, values) => {
      if (!err) {
        this.setState({ loadSpin: true });
        doCreateUser(values)
          .then((action) => {
            this.setState({
              isModalVisible: true,
              password: action.payload.data.password,
              loadSpin: false,
            });
            form.resetFields();
          })
          .catch((action) => {
            let errorMessage;
            if (action.error.response.status === 400) {
              errorMessage = action.error.response.data.message;
              this.setState({
                loadSpin: false,
              });
            } else {
              errorMessage = 'Something went wrong, please try again later.';
              this.setState({
                loadSpin: false,
              });
            }
            message.error(errorMessage, 3);
          });
      }
    });
  }

  handleClose() {
    this.setState({
      password: null,
      isModalVisible: false,
    });
  }

  render() {
    const {
      isFetching,
      options,
      password,
      isModalVisible,
      loadSpin,
      isValidating,
      roleValidate,
    } = this.state;
    const { form } = this.props;
    const { getFieldDecorator } = form;

    const breadCrumbs = [
      {
        breadcrumbName: 'Admin',
      },
      {
        breadcrumbName: 'Users',
      },
      {
        breadcrumbName: 'Create',
      },
    ];

    return (
      <div className="UserCreate">
        <PageHeader title="Users" routes={breadCrumbs} />
        <Container>
          {password !== null ? (
            <Modal
              closable={false}
              title="Result"
              visible={isModalVisible}
              footer={[
                <Button key="back" onClick={this.handleClose}>
                  Close
                </Button>,
              ]}
            >
              <Result
                status="success"
                title="Successfully created user!"
                subTitle={`The user's password is ${password}`}
              />
            </Modal>
          ) : null}
          <Card title="User Information">
            <Spin spinning={loadSpin} tip="Loading User Create...">
              <Form onSubmit={this.formSubmit}>
                <Row>
                  <Col xs={24} sm={12} lg={6}>
                    <Form.Item label="Username" hasFeedback={isValidating}>
                      {getFieldDecorator('username', {
                        rules: [
                          {
                            required: true,
                            message: 'Username cannot be blank.',
                          },
                          { validator: this.validateUsername },
                          {
                            min: 5, message: 'Username must have 5 or more characters.',
                          },
                        ],
                      })(
                        <Input
                          placeholder="Enter username"
                          autoComplete="off"
                        />,
                      )}
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} lg={6}>
                    <Form.Item label="First Name">
                      {getFieldDecorator('firstname', {
                        rules: [
                          {
                            required: true,
                            message: 'First Name cannot be blank.',
                          },
                        ],
                      })(
                        <Input
                          placeholder="Enter first name"
                          autoComplete="off"
                        />,
                      )}
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} lg={6}>
                    <Form.Item label="Last Name">
                      {getFieldDecorator('lastname', {
                        rules: [
                          {
                            required: true,
                            message: 'Last Name cannot be blank.',
                          },
                        ],
                      })(
                        <Input
                          placeholder="Enter last name"
                          autoComplete="off"
                        />,
                      )}
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} lg={6}>
                    <Form.Item label="Employee Number">
                      {getFieldDecorator('employee_no', {
                        rules: [
                          {
                            required: true,
                            message: 'Employee Number cannot be blank.',
                          },
                        ],
                      })(
                        <Input
                          placeholder="Enter employee number"
                          autoComplete="off"
                        />,
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} sm={12} lg={6}>
                    <Form.Item label="Mobile Number.">
                      {getFieldDecorator('mobile_no', {
                        rules: [
                          {
                            required: true,
                            message: 'Mobile Number. cannot be blank.',
                          },
                        ],
                      })(
                        <Input
                          placeholder="Enter mobile number"
                          autoComplete="off"
                        />,
                      )}
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} lg={6}>
                    <Form.Item label="Port Code">
                      {getFieldDecorator('port_code', {
                        rules: [
                          {
                            required: true,
                            message: 'Port Code cannot be blank.',
                          },
                        ],
                      })(
                        <Select
                          options={options.destination}
                          placeholder="Select port code"
                          allowClear
                          loading={isFetching}
                          onSearch={e => this.searchOption('destination', e)}
                          filterOption={false}
                          showSearch
                        />,
                      )}
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} lg={6}>
                    <Form.Item label="Portal Role">
                      {getFieldDecorator('role_id', {
                        rules: [
                          {
                            required: true,
                            message: 'Portal Role cannot be blank.',
                          },
                        ],
                      })(
                        <Select
                          options={options.role}
                          placeholder="Enter portal role"
                          allowClear
                          loading={isFetching}
                          onSearch={e => this.searchOption('role', e)}
                          filterOption={false}
                          showSearch
                        />,
                      )}
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} lg={6}>
                    <Form.Item label="Mobile Role">
                      {getFieldDecorator('mobile_role', {
                        rules: [
                          {
                            required: true,
                            message: 'Mobile Role cannot be blank.',
                          },
                        ],
                      })(
                        <Select
                          options={options.mobile_role}
                          placeholder="Select mobile role"
                          allowClear
                          loading={isFetching}
                          onSearch={e => this.searchOption('mobile_role', e)}
                          onSelect={e => this.roleValidator(e)}
                          filterOption={false}
                          on
                          showSearch
                        />,
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} sm={24} lg={roleValidate ? 12 : 18}>
                    <Form.Item label="Clients">
                      {getFieldDecorator('clients', {
                        rules: [
                          {
                            required: false,
                            message: 'Clients cannot be blank.',
                          },
                          {
                            validator: (rule, value, callback) => {
                              if (value) {
                                if (value.length > 5) {
                                  callback('Cannot select more than 5 clients.');
                                } else if (value.length <= 5) {
                                  callback();
                                }
                              }
                            },
                          },
                        ],
                        initialValue: [],
                      })(
                        <Select
                          allowClear
                          mode="multiple"
                          loading={isFetching}
                          options={options.client}
                          onSearch={e => this.searchOption('client', e)}
                          filterOption={false}
                          placeholder="Type to search for desired clients"
                          showSearch
                        />,
                      )}
                    </Form.Item>
                  </Col>
                  {
                    roleValidate ? (
                      <Col xs={24} sm={24} lg={6}>
                        <Form.Item label="Accumatica ID">
                          {getFieldDecorator('accumatica_id', {
                            rules: [
                              {
                                required: roleValidate,
                                message: 'Accumatica ID cannot be blank.',
                              },
                            ],
                          })(
                            <Input
                              placeholder="Enter accumatica id"
                              autoComplete="off"
                            />,
                          )}
                        </Form.Item>
                      </Col>
                    ) : null
                  }
                  <Col xs={24} sm={24} lg={6}>
                    <Form.Item label="Outsource">
                      {getFieldDecorator('outsource_group', {
                        rules: [
                          {
                            required: true,
                            message: 'Outsource cannot be blank.',
                          },
                        ],
                        initialValue: [],
                      })(
                        <Select
                          allowClear
                          loading={isFetching}
                          options={options.outsource}
                          onSearch={e => this.searchOption('outsource', e)}
                          filterOption={false}
                          placeholder="Type to search for desired outsource"
                          showSearch
                        />,
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                <Col xs={24} sm={24} lg={roleValidate ? 12 : 18}>
                <Form.Item label="Email">
                      {getFieldDecorator('email', {
                        rules: [
                          {
                            required: false,
                            message: 'Email cannot be blank.',
                          },
                        ],
                      })(<Input
                        type='email'
                        allowClear
                        placeholder="Type the email here"
                        showSearch
                      />)}
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button type="primary" htmlType="submit" block>
                      <Icon viewBox="0 0 1024 1024">
                        <FontAwesomeIcon icon={faPlus} fixedWidth />
                      </Icon>
                      Create
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Spin>
          </Card>
        </Container>
      </div>
    );
  }
}

Create.propTypes = {
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  doFetchOptionsLike: PropTypes.func.isRequired,
  doFetchOptions: PropTypes.func.isRequired,
  doCreateUser: PropTypes.func.isRequired,
  doValidateUsername: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  doFetchOptionsLike: fetchOptionsLike,
  doFetchOptions: fetchOptions,
  doCreateUser: createUser,
  doValidateUsername: validateUsername,
};

const WrappedCreate = Form.create({ name: 'create' })(Create);

export default connect(null, mapDispatchToProps)(WrappedCreate);
